.all-daily-profit-loss-table {
    .ant-card {
        border-radius: 10px;
        .ant-card-body {
            display:flex;
            position:relative;
            .pnl-ratio {
                position: absolute;
                margin: 0 auto;
                left: 0;
                right: 0;
                background: #fbfafa;
                width: fit-content;
                top: 0;
                padding: 10px 15px;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                font-weight: 500;
                font-size: 14px;
                border: 1px solid #f0f0f0;
            }
        }
    }
    .ant-statistic {
        flex-grow:1;
        .ant-statistic-title {
            color:#000;
            font-weight:500;
        }
        .ant-statistic-content {
            font-weight: 500;
        }
    }

    .ant-tag {
        font-size: 13px;
        padding: 5px 10px;
        border-radius: 4px;
        font-weight: 500;
    }

    .ant-table-tbody {
        tr {
            td {
                font-weight:500;
            }
        }
    }

    .symbol {
        display: flex;
        align-items: center;
        a {
            color:#000;
            display: flex;
        }
        img {
            height: 18px;
            margin-right: 6px;
        }
        .name {
            display:inline-block;
            max-width:70px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .anticon-history {
            height:24px;
        }
    }

    .reverse {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span.reverse-index {
            background: #ff5a5a;
            color: #fff;
            font-size: 11px;
            display: flex;
            height: 20px;
            text-align: center;
            justify-content: center;
            align-items: center;
            line-height: 0;
            position: absolute;
            right: 0;
            top: 0;
            padding: 0 5px;
            border-bottom-left-radius: 4px;
        }
    }
    .deal-strength {
        display: flex;
        justify-content: center;
        font-weight: 700 !important;
        .CircularProgressbar-text {
            dominant-baseline: central;
        }
    }
}