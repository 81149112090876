.currency-info {
    border:1px solid #f0f0f0;
    border-radius: 10px;
    margin-bottom: 20px;
    padding:20px;
    .risk {
        width: 10px;
        height: 10px;
        background: #333;
        display: block;
        margin-left: 5px;
        border-radius: 50%;
        //border: 1px solid #333;
        position: absolute;
        top: 10px;
        right: 20px;
        &.red {
            background-color: #f50;
            border-color:#f56e2a;
        }
        &.green {
            background-color: #2dd92a;
            border-color:#6bff68;
        }
        &.yellow {
            background-color: #ffcc12;
            border-color:#f7cb58;
        }
        &.star {
            background-color: inherit;
            border-color: transparent;
            width: auto;
            height: auto;
            .anticon {
            }
        }
    }

    .currency-image {
        padding:10px 20px;
        img {
            height:130px;
        }
    }
    .currency-title {
        margin:10px 0;
        p {
            margin:0;
            padding:0;
            font-size:20px;
            font-weight: 500;
        }
        span {}
    }
    .currency-price {
        margin:10px 0;
        p {
            font-weight: 700;
            font-size:25px;
            margin:0;
            padding:0;
            &.green {
                color:#0ecb81;
            }
            &.red {
                color:#f6465d;
            }
        }
    }
    .currency-links {
        ul {
            padding:0;
            margin:0;
            li {
                list-style: none;
                padding:5px 0;
                a {
                    border:1px solid #f0f0f0;
                    background-color: #fbfbfb;
                    padding:5px 10px;
                    display:block;
                    color:#000;
                    border-radius: 6px;
                    transition:all .2s linear;
                    &:hover {
                        background-color: #efefef;
                    }
                }
            }
        }
    }
    .ta-widget {
        display:flex;
        justify-content:center;
    }
}