.wallet {
    .green {
        color:#2db52d;
    }
    .red {
        color:#f6465d;
    }
    .wallet-header {
        .ant-row {
            align-items:center;
        }
        .wallet-title {
            //color: #e5ac17;
            font-size: 47px;
            font-weight: 900;
            margin: 0;
            letter-spacing: -3px;
            text-align: left;
            background: -webkit-linear-gradient(0deg, #e5ac17, #f7931a 200%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            span {
                //color: #f7931a;
            }
        }
        .realized {
            font-size: 16px;
            text-align: left;
            font-weight: 400;
            margin: 0;
            display: inline-block;
            //border: 1px solid #ededed;
            padding: 10px 15px;
            span {
                font-weight: 700;
                //color: #2ca52c;
                color: #2dbd2d;
            }
        }
        .active-balance {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            display: inline-block;
            //border: 1px solid #ededed;
            padding: 10px 15px; 
            border-left: 1px solid #dfdfdf;
            border-right: 1px solid #dfdfdf;
            span {
                font-weight: 700;
            }      
        }
        .current-pnl {
            margin: 0;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            display: inline-block;
            //border: 1px solid #ededed;
            padding: 10px 15px;
            span {
                font-weight: 700;
            }
        }
        .item.count {
            text-align: left;
            font-size: 16px;
            display: flex;
            p {
                display:inline-block;
                margin-right:10px;
            }
            span {
                font-weight:700;
            }
            .market-list {
                span {
                    font-weight:500;
                }
            }
        }
        .switch-mode {
            display: flex;
            justify-content: end;
            margin-bottom: 10px;
            .ant-switch {
                background-color: #E5AC17;
            }
        }

        .wallet-action {
            display:flex;
            justify-content: flex-end;
            .ant-btn {
                margin-left:10px;
            }
        }

        .price-area {
            display: inline-block;
            background: #ffffff;
            border: 1px solid #dfdfdf;
            border-radius: 8px;
            -webkit-box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.32);
            -moz-box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.32);
            -webkit-box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.2);
            -moz-box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.2);
            box-shadow: 0px 0px 22px 0px rgba(0,0,0,0.12);
        }
    }
    .currency-search {
        width: 50%;
        margin: 0 auto;
        padding: 20px;
        margin-bottom: 20px;
        .ant-input-affix-wrapper {
            border-radius: 6px;
        }
        .ant-input {
            line-height: 40px;
        }
    }
}

@media screen and (max-width: 768px) {
    .wallet {
        .wallet-header {
            .ant-row {
                align-items:center;
            }
            .wallet-title {
                text-align: center;
                font-size:36px;
            }
            .active-balance {
                border:none;
            }
            .price-area {
                box-shadow: none;
                margin: 10px 0;
            }
            .wallet-action {
                justify-content: space-between;
                .ant-btn {
                    margin-left: 0;
                }
            }
            .item.count {
                justify-content: space-between;
                margin: 15px 0;
                p {
                    margin-bottom:0;
                }
            }
            .switch-mode {
                margin-bottom: 10px;
                justify-content: space-between;
            }
        }
        .ant-table-tbody {
            tr {
                td {
                    padding: 5px !important;
                    font-size: 12px;
                    .ant-btn-circle {
                        min-width: 24px;
                    }
                    .ant-btn-icon-only {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }


  }