.last-24-hours-deals-table {
    .ant-tag {
        font-size: 13px;
        padding: 5px 10px;
        border-radius: 4px;
        font-weight: 500;
    }

    .ant-table-tbody {
        tr {
            td {
                font-weight:500;
            }
        }
    }

    .symbol {
        display: flex;
        align-items: center;
        a {
            color:#000;
            display:flex;
            align-items: center;
        }
        img {
            height: 18px;
            margin-right: 6px;
        }
        span {

        }
        .risk {
            width: 10px;
            height: 10px;
            background: #333;
            display: block;
            margin-left: 5px;
            border-radius: 50%;
            //border:1px solid #333;
        }
        .red {
            background-color: #f50;
            border-color:#f56e2a;
        }
        .green {
            background-color: #2dd92a;
            border-color:#6bff68;
        }
        .yellow {
            background-color: #ffcc12;
            border-color:#f7cb58;
        }
        .star {
            background-color: inherit;
            border-color: transparent;
            width: auto;
            height: auto;
            .anticon {
            }
        }
        .anticon-history {
            height:24px;
        }
    }
    .price {
        .price-text {
            padding-right: 10px;
        }
    }
    .reverse {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span.reverse-index {
            background: #ff5a5a;
            color: #fff;
            font-size: 11px;
            display: flex;
            height: 20px;
            text-align: center;
            justify-content: center;
            align-items: center;
            line-height: 0;
            position: absolute;
            right: 0;
            top: 0;
            padding: 0 5px;
            border-bottom-left-radius: 4px;
        }
    }
}