.home {
    display: flex;
    justify-content: center;

    .analysis-header {
        max-width: 900px;
    width: 100%;
    }

    .btn-analysis {
        margin-top: 20px;
        background-color: #e5ac17;
        border-color: #e5ac17;
        height: 60px;
        padding: 0 30px;
        border-radius: 6px;
        transition:all 0.2s linear;
        margin-bottom:10px;
        &:hover {
            background-color: #f7931a;
            border-color: #f7931a;         
        }
    }
}