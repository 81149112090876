.add-new-process-modal {
    .ant-col {
        margin-bottom:10px;
    }

    .form-label {
        width: 100%;
        text-align: left;
        display: inline-block;
        margin-bottom: 3px;
    }
    .ant-input-number {
        width: 100%;
        margin-bottom: 0px;
        .ant-input-number-handler-wrap {
            display:none;
        }
        input {
            margin-bottom:0;
        }
    }

    .ant-form-item-control {
        margin:0;
    }

    .ant-form-item {
        margin-bottom:0;
    }

    .ant-form-item-label {
        padding:0;
        margin-bottom:3px;
    }

    .ant-form-item-required {
        &:before {
            content: '' !important;
            margin-right: 0 !important;
        }
    }


    .history-info {
        margin-bottom:10px;
        .history-image {
            text-align: center;
            img {
                height:50px;
            }
            p {
                margin:0;
            }
        }
        .history-prices {
            display: flex;
            flex-direction: column;
            justify-content: center;
            border: 1px solid #e9e9e9;
            p {
                font-weight:400;
                font-size:14px;
                margin: 0;
                padding: 5px 15px;
                span {
                    font-weight:600;
                }
            }
            &.current {
                border-color: #04bd3c;
                border-style: dashed;
                p {
                    color: #086b10;
                }
            }
        }
    }

}