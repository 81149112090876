.current-wallet-table {
    width: 100%;
    .green {
        color:#2db52d;
    }
    .red {
        color:#f6465d;
    }
    .ant-tag {
        font-size: 13px;
        padding: 5px 10px;
        border-radius: 4px;
        font-weight: 500;
    }

    .action {
        text-align:center;
    }
    .ant-table-tbody {
        tr {
            td {
                font-weight:500;
            }
            .ant-table-cell-row-hover {
                 background:#ffeabf !important;   
            }
        }
    }

    .symbol {
        display: flex;
        align-items: center;
        a {
            color:#000;
            display:flex;
            align-items: center;
        }
        img {
            height: 18px;
            margin-right: 6px;
            width: 25px;
        }
        span {

        }
        .risk {
            width: 10px;
            height: 10px;
            background: #333;
            display: block;
            margin-left: 5px;
            border-radius: 50%;
            //border:1px solid #333;
        }
        .red {
            background-color: #f50;
            border-color:#f56e2a;
        }
        .green {
            background-color: #2dd92a;
            border-color:#6bff68;
        }
        .yellow {
            background-color: #ffcc12;
            border-color:#f7cb58;
        }
        .star {
            background-color: inherit;
            border-color: transparent;
            width: auto;
            height: auto;
            .anticon {
            }
        }
        .pos-period {
            margin-left:5px;
            .mid {
                color: #e5ac17;
            }
            .short {
                color:#d74a1d;
            }
        }
    }

    .price {
        .price-text {
            padding-right: 10px;
            font-weight: 500;
        }
    }
    .ema {
        display:flex;
    }

    .deal-strength {
        display: flex;
        justify-content: center;
        font-weight: 700 !important;
        .CircularProgressbar-text {
            dominant-baseline: central;
        }
    }

    .table-action {
        display: flex;
        justify-content: center;
        .ant-btn {
            margin:0 5px;
        }
    }
}

@media screen and (max-width: 768px) {
    .current-wallet-table {
        overflow-x: auto;
    }
    .table-action {
        .anticon-history {
            height:24px;
            margin-top:-3px;
        }
    }
  }